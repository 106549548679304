import React, { useEffect } from 'react';


const ShortenerPage = () => {
  // Hardcoded custom URI to original URL mapping
  const customUriMappings = {
    short1: 'https://www.microdegree.work/',

    microdegree: 'https://www.microdegree.work',


    zoom1: 'https://us06web.zoom.us/meeting/register/tZMldOqqpjgoHtZHQP1JxsZYsAhhDNnG6Vhj',
    zoom2: 'https://us06web.zoom.us/meeting/register/tZIsfu6qqDgrH9z3ftHwQhntnQQIetUOyypS',

    zoom_link_eg: 'https://us06web.zoom.us/meeting/register/tZMldOqqpjgoHtZHQP1JxsZYsAhhDNnG6Vhj',
    zoom_link_mg: 'https://us06web.zoom.us/meeting/register/tZIsfu6qqDgrH9z3ftHwQhntnQQIetUOyypS',    
    zoom_link_beg_: 'https://us06web.zoom.us/meeting/register/tZMldOqqpjgoHtZHQP1JxsZYsAhhDNnG6Vhj',
    zoom_link_bmg_: 'https://us06web.zoom.us/meeting/register/tZIsfu6qqDgrH9z3ftHwQhntnQQIetUOyypS',
    demo_session_vid: 'https://youtu.be/-41Lo2DENCI',
    zoom_link_mail_eg: 'https://us06web.zoom.us/meeting/register/tZMldOqqpjgoHtZHQP1JxsZYsAhhDNnG6Vhj',
    zoom_link_mail_mg: 'https://us06web.zoom.us/meeting/register/tZIsfu6qqDgrH9z3ftHwQhntnQQIetUOyypS',    
    demo_mail_vid: 'https://youtu.be/-41Lo2DENCI',

    zoom_link: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_link_: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_joining_link_: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_link_beg: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_link_beg1: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_link_beg2: 'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09',
    zoom_link_m: 'https://us06web.zoom.us/j/86197263158?pwd=bXdEZi9BZ3lDdWxFclo0cktReHVCZz09',
    zoom__link_bmg: 'https://us06web.zoom.us/j/86197263158?pwd=bXdEZi9BZ3lDdWxFclo0cktReHVCZz09',

    demo_registration_website: 'https://tally.so/r/wve8pl?utm_source=web&utm_medium=page&utm_content=demo&utm_campaign=registration',
    demo_joining_link: 'https://event.webinarjam.com/go/live/105/wmo75hq0cmslsx',

    demo_joining_link_web: 'https://event.webinarjam.com/register/142/g46v9h53',
    webjam_demo_link_bmg: 'https://event.webinarjam.com/register/142/g46v9h53',

    workshop: 'https://pages.razorpay.com/pl_OEHkbiZcCXe0Jn/view',
    workshop_link_android: 'https://pmny.in/PAYUMN/Xrdq3MAV4uyO',

    demo_registration_app: 'https://tally.so/r/wve8pl?utm_source=android_app&utm_medium=app&utm_content=demo&utm_campaign=registration',
    webinar_join: 'https://event.webinarjam.com/go/live/81/x684mbw3s7sps9',
    ebook_android: 'https://tally.so/r/mOzXBp?utm_source=android_app&utm_medium=app&utm_content=ebook&utm_campaign=registration',
    scholorship_android:'https://tally.so/r/mOzMQY?utm_source=android_app&utm_medium=app&utm_content=scholorship&utm_campaign=registration',
    devops_for_kannadiga_android:'https://tally.so/r/wvL0bv?utm_source=android_app&utm_medium=app&utm_content=devops_kannada&utm_campaign=registration',
    python_for_kannadiga_android:'https://tally.so/r/3xPlGr?utm_source=android_app&utm_medium=app&utm_content=python_kannada&utm_campaign=registration',
    youtube_channel_android:'https://www.youtube.com/channel/UCu8l4v6xqQd8LfOfd0kMPsA',
    free_course_android:'https://www.microdegree.work/YTFullCoursePage',
    workshop_enroll_android:'https://pages.razorpay.com/pl_OEHkbiZcCXe0Jn/view',
    mega_offer_android:'https://rzp.io/l/0QBxJHpz',
    pro_offer_android:'https://courses.microdegree.work/pages/microdegree-pro',
    prime_offer_android:'https://pages.razorpay.com/pl_Igpdd18w45gbmw/view',
    free_certification_courses_android:'https://microdegree.thinkific.com/pages/free-courses',


    aws_devops_premium_android:'https://pages.razorpay.com/pl_Hzmgy7nIY3dgBn/view',
    aws_devops_standard_android:'https://pages.razorpay.com/pl_OcogTnND7msqW3/view',
    aws_devops_premium_form_android:'https://tally.so/r/nP9245?utm_source=android_app&utm_medium=app&utm_content=aws_devops_premium&utm_campaign=registration',
    aws_devops_standard_form_android:'https://tally.so/r/nP9245?utm_source=android_app&utm_medium=app&utm_content=aws_devops_standard&utm_campaign=registration',
    python_fullstack_form_android:'https://tally.so/r/nrEazR?utm_source=android_app&utm_medium=app&utm_content=python_fullstack&utm_campaign=registration',


    programming_combo_rec_android:'https://pages.razorpay.com/pl_KWNG2OcMsON5WQ/view',
    c_programming_rec_android:'https://pages.razorpay.com/pl_FSuZUEvIKUJJ4q/view',
    aws_rec_android:'https://pages.razorpay.com/pl_IgpWY8QuJtrMBG/view',
    devops_rec_android:'https://pages.razorpay.com/pl_IgpY1qrKQxXFq9/view',
    manual_testing_rec_android:'https://pages.razorpay.com/pl_I1Ap25DtDer9vx/view',
    mysql_rec_android:'https://pages.razorpay.com/pl_FhdDE1K3PHcpsW/view',
    ai_ml_rec_android:'https://pages.razorpay.com/pl_FxrgLqRxLQhWAM/view',
    data_analytics_rec_android:'https://pages.razorpay.com/pl_Fxre4B7uGERuZy/view',
    automation_selenium_rec_android:'https://pages.razorpay.com/pl_HX7iIyifovAqY4/view',
    python_rec_android:'https://pages.razorpay.com/pl_FMa8744mElRPyG/view',
    python_fullstack_freshers_android:'https://trainings.microdegree.work/learn/fast-checkout/190359',
    microdegree_pro_freshers_android:'https://pages.razorpay.com/pl_Gev62ka8kgBbte/view',
    web_development_freshers_android:'https://pages.razorpay.com/pl_G8ZWKUmbyXe0Pj/view',
    javascript_freshers_android:'https://pages.razorpay.com/pay-md-js-masterclass',

    aws_mini_course_free_android:'https://youtu.be/6ZGtOe---X8?si=XUHeXxkqDxAtRgN3',
    introduction_to_python_free_android:'https://youtu.be/i5B9JPq3MbI',
    javascript_foundation_free_android:'https://youtu.be/ULzUzc0gMfI',
    css_foundation_free_android:'https://youtu.be/y6fzqBsi1-Y',
    html_foundation_free_android:'https://youtu.be/y6fzqBsi1-Y',
    git_free_android:'https://youtube.com/playlist?list=PLQztdyH5OY4BWDNU1xK-2xp8iSvfg0Sg9&si=lvLlHqmCda7StKvP',
    c_programming_free_android:'https://youtube.com/playlist?list=PLQztdyH5OY4D3HIETAntxASLgvVh4JqDT&si=4p-Dk_ZHUfkSXwK3',
    introduction_to_mysql_free_android:'https://youtu.be/exqFMNJKDOE',
    introduction_to_c_plus_plus_free_android:'https://youtube.com/playlist?list=PLQztdyH5OY4DYIUKLoXsITMT626Sbz4aI&si=zFrte6xxWQ4huyWs',

    fullstack_development_mern_android:'https://pages.razorpay.com/pl_I1ApnyTgMxKr02/view',
    html_css_course_android:'https://pages.razorpay.com/pl_Fn6r23s3PrJTHp/view',
    frontend_development_course_android:'https://pages.razorpay.com/pl_G3O9dGRsvXAq8I/view',
    software_developer_course_android:'https://pages.razorpay.com/pl_GyHuMMdDCzxP9K/view',
    reactjs_course_android:'https://pages.razorpay.com/pl_GXWpTqhYQWboXY/view',

    java_course_android:'https://pages.razorpay.com/pl_FQCKZwhbSrIYGH/view',
    manual_testing_program_android:'https://pages.razorpay.com/pl_I1Ap25DtDer9vx/view',
    aptitude_course_android:'https://pages.razorpay.com/pl_HmzDUu5fT0TMg9/view',
    aws_certifications_webinar_android:'https://pages.razorpay.com/pl_ORLzUDvKzzAJrk/view?webinar=RoadMap%20for%20DevOps%20Engineer',
    cloud_computing_with_aws_webinar_android:'https://pages.razorpay.com/pl_OS4vKvfGQ62iWf/view?webinar=Cloud%20computing%20with%20aws',
    career_in_cloud_devops_webinar_android:'https://pages.razorpay.com/pl_OS7xojbMl2iFs0/view?webinar=Career%20in%20cloud%20and%20devops',
    project_for_aws_course_android:'https://pages.razorpay.com/pl_OW6ipnIH846kSy/view?course=Project%20for%20AWS',
    project_for_devops_course_android:'https://pages.razorpay.com/pl_OW6lpNBzzkyst3/view?course=Project%20for%20DevOps',
    aws_introductory_course_android:'https://pages.razorpay.com/pl_OMXlXwwBzjIcNr/view?course=AWS%20Introductory%20Course',
    devops_introductory_course_android:'https://pages.razorpay.com/pl_OMXnvOejrOkxzE/view?course=DevOps%20Introductory%20Course',
    resume_for_cloud_engineer_android:'https://pages.razorpay.com/pl_OUvAkxnAV60F0d/view?course=Resume%20for%20Cloud%20Engineer',

    free_aiml_android: 'https://microdegree.thinkific.com/enroll/3132762?price_id=4008851',
    free_python_android: 'https://microdegree.thinkific.com/enroll/3132605?price_id=4008665',
    free_java_android: 'https://microdegree.thinkific.com/enroll/3132582?price_id=4008640',
    free_cplus_plus_android: 'https://microdegree.thinkific.com/enroll/3132635?price_id=4008697',
    free_data_analytics_android: 'https://microdegree.thinkific.com/enroll/3132836?price_id=4008943',
    free_tableau_android: 'https://microdegree.thinkific.com/enroll/3134100?price_id=4010379',
    free_aws_android: 'https://microdegree.thinkific.com/enroll/3132791?price_id=4008880',
    free_azure_android: 'https://microdegree.thinkific.com/enroll/3132799?price_id=4008888',
    free_devops_android: 'https://microdegree.thinkific.com/enroll/3132771?price_id=4008860',
    free_docker_android: 'https://microdegree.thinkific.com/enroll/3132805?price_id=4008896',
    free_kubernetes_android: 'https://microdegree.thinkific.com/enroll/3132822?price_id=4008920',
    free_linux_android: 'https://microdegree.thinkific.com/enroll/3132806?price_id=4008898',
    free_networking_android: 'https://microdegree.thinkific.com/enroll/3132827?price_id=4008925',
    free_mysql_android: 'https://microdegree.thinkific.com/enroll/3132847?price_id=4008956',
    free_mongodb_android: 'https://microdegree.thinkific.com/enroll/3134090?price_id=4010367',
    free_html_css_android: 'https://microdegree.thinkific.com/enroll/3132756?price_id=4008845',
    free_javascript_android: 'https://microdegree.thinkific.com/enroll/3132748?price_id=4008837',
    free_reactjs_android: 'https://microdegree.thinkific.com/enroll/3132831?price_id=4008929',
    free_nodejs_android: 'https://microdegree.thinkific.com/enroll/3132864?price_id=4008973',
    free_php_android: 'https://microdegree.thinkific.com/enroll/3132834?price_id=4008934',
    free_selenium_testing_android: 'https://microdegree.thinkific.com/enroll/3132848?price_id=4008957',
    free_manual_testing_android: 'https://microdegree.thinkific.com/enroll/3132860?price_id=4008969',
    free_git_android: 'https://microdegree.thinkific.com/enroll/3131315?price_id=4007184',
    free_cprogram_android: 'https://microdegree.thinkific.com/enroll/3132714?price_id=4008801',
    free_powerbi_android: 'https://microdegree.thinkific.com/enroll/3132854?price_id=4008963',
    free_aptitude_android: 'https://microdegree.thinkific.com/enroll/3132797?price_id=4008886',
    aws_mini_course_android: 'https://microdegree.thinkific.com/enroll/2990772?price_id=3847042',



    workshop_joining_link: 'https://event.webinarjam.com/register/140/5yzoxil8',
    workshop_session_link: 'https://event.webinarjam.com/go/live/140/5yzoxil8h7s8sv',

    demo_recording_link: 'https://event.webinarjam.com/go/live/105/wmo75hq0cmslsx',

    mini_course_link: 'https://rzp.io/l/ohKVNtwGo',
    mini_course: 'https://pmny.in/PAYUMN/9rb1gAVOVjX4',


    webinar_replay_link_a: 'https://event.webinarjam.com/go/live/137/4yw0xi2pbksws6',

    webinar_replay_link_d: 'https://event.webinarjam.com/go/live/144/2y23xi60a7s6sk',

    webinar_replay: 'https://event.webinarjam.com/go/live/81/x684mbw3s7sps9',

    webinar_replay_link: 'https://event.webinarjam.com/go/replay/81/8y210i1lho6i0so',

    microdegree_em: 'https://tally.so/r/waxdkv',
    yt_ebook: 'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FHow%20to%20become%20a%20Cloud%20Professional.pdf?alt=media&token=d74adfbe-5fb1-4187-ae5b-bfd66a8763ad',
    yt_cloud_vid: 'https://youtu.be/hwo5ymx_Xos?si=XGy8vAuf0Yw-MPIM',
    yt_tech_vid: 'https://youtu.be/xyRx9k4Ou34?si=EcIUtRJxWE4NJANK',
    yt_python_vid: 'https://youtu.be/i5B9JPq3MbI?si=qRPlJCvQ0eQtz-AE',
    yt_minicourse_vid: 'https://youtu.be/MjOo6jNRJfc',
    aws_vpc_blog: 'https://www.linkedin.com/feed/update/urn:li:activity:7197528870948913153',
    interview_prep_tips: 'https://www.youtube.com/watch?v=gVaEzeOB63Y',
    resume_building_tips: 'https://www.youtube.com/watch?v=rHkVwa7C-yI',
    cloud_vs_devops: 'https://www.youtube.com/watch?v=q0ev6AAO0LU',
    success_stories: 'https://www.youtube.com/watch?v=lCcLo0ryapI',







    python_fullstack: 'https://microdegree.thinkific.com/courses/python-fullstack',
    
    python_certification: 'https://courses.microdegree.work/courses/python',
    mysql_certification: 'https://courses.microdegree.work/courses/mysql',
    javascript_certification: 'https://courses.microdegree.work/courses/JavaScriptMasterClass',
    reactjs: 'https://courses.microdegree.work/courses/react',
    web_development: 'https://courses.microdegree.work/courses/web-development',
    data_analytics: 'https://courses.microdegree.work/courses/data-analytics',
    frontend_development: 'https://courses.microdegree.work/courses/frontend-development',
    software_developer: 'https://courses.microdegree.work/courses/software-developer',
    automation_testing_selenium: 'https://courses.microdegree.work/courses/automation-testing-selenium',
    aptitude: 'https://courses.microdegree.work/courses/aptitude',
    c_programming: 'https://courses.microdegree.work/courses/c-programming',
    c_plus_plus_certification: 'https://courses.microdegree.work/courses/C-plus-plus',
    html_and_css: 'https://courses.microdegree.work/courses/html-css',
    php: 'https://courses.microdegree.work/courses/php-program',
    manual_testing: 'https://courses.microdegree.work/courses/manual-testing-recorded',
    manual_python_automation: 'https://courses.microdegree.work/courses/manual-python-automation',
    java_selenium_automation: 'https://courses.microdegree.work/courses/java-selenium-automation',
    ai_and_ml: 'https://microdegree.thinkific.com/courses/machine-learning',
    powerbi: 'https://trainings.microdegree.work/learn/Power-Bi',
    ai_ml: 'https://microdegree.thinkific.com/courses/machine-learning',







    courses_: 'https://courses.microdegree.work/pages/premium-courses',
    free_courses_: 'https://courses.microdegree.work/pages/free',
    free_course_enq: 'https://tally.so/r/3X0Jk4?utm_source=web&utm_medium=ytfcpage&utm_content=free_course&utm_campaign=enquiry',



    linkedIn_md:'https://www.linkedin.com/company/microdegree/?utm_source=web&utm_medium=page&utm_content=LinkedIn&utm_campaign=registration',
    facebook_md:'https://www.facebook.com/microdegree?utm_source=web&utm_medium=page&utm_content=Facebook&utm_campaign=registration',
    instagram_md:'https://www.instagram.com/microdegree.work?utm_source=web&utm_medium=page&utm_content=Instagram&utm_campaign=registration',
    twitter_md:'https://twitter.com/micro_degree?utm_source=web&utm_medium=page&utm_content=Twitter&utm_campaign=registration',
    telegram_md:'https://telegram.me/microdegreekannada?utm_source=web&utm_medium=page&utm_content=Telegram&utm_campaign=registration',


    enquiry: 'https://tally.so/r/3NXB4l?utm_source=web&utm_medium=provesource&utm_content=provesource&utm_campaign=enquiry',
    demo_enquiry: 'https://tally.so/r/n9ZM9Q?utm_source=tally&utm_medium=demo&utm_content=message&utm_campaign=enquiry',
    refer: 'https://tally.so/r/mV0adN?utm_source=web&utm_medium=provesource&utm_content=refer&earn&utm_campaign=enquiry',
    webinars: 'https://www.microdegree.work/webinar',
    register: 'https://tally.so/r/wd9Yko',
    demo_issues_responses: 'https://tally.so/r/3NXyEp?utm_source=tally&utm_medium=email&utm_content=demo-issues&earn&utm_campaign=responses',


    

    ytdemoinst: 'https://youtu.be/-41Lo2DENCI',

    minicourse:'https://trainings.microdegree.work/learn/aws-and-devops-mini-course',

    onbvid:'https://youtu.be/wlwC74aPJeQ',

    dashboard_vid:'https://youtu.be/C2565N4TYBU',

    referral:'https://trainings.microdegree.work/learn/account/become-affiliate',

    referralmaildoc:'https://bitly.ws/3b6VM',

    refer_and_earn:'https://tally.so/r/mVl6dE',

    referralmsgdoc:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/Referral%20Program%20(8).pdf?alt=media&token=75fd017a-a727-45de-ad6d-8ae5a26733f0',

    ebook1:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FE-Book.pdf?alt=media&token=9dd03792-9dd5-4667-ad81-4b3907f0c50d',

    ebook2:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FE-Book%20V2.pdf?alt=media&token=c4a2fd95-afaa-4013-ab66-e1e02b98d2bf',

    ebook:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FHow%20to%20become%20a%20Cloud%20Professional.pdf?alt=media&token=d74adfbe-5fb1-4187-ae5b-bfd66a8763ad',


    aws_interview_prep_guide:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/Certificate%2FAWS%20Preparation%20questions%20using%20chat%20GPT.pdf?alt=media&token=ae457237-8455-445f-bc68-822f4b16ac27',


    faq:'https://www.youtube.com/playlist?list=PLQztdyH5OY4AbagEFRIrzdEzia5gl7umW',

    freshers:'https://youtu.be/zjKL3vpQ6jA',

    nonit:'https://youtu.be/L7SQ-RmHx74',

    testimonialvideo:'https://youtu.be/fn21khUFr8w',

    devopswebinar:'https://event.webinarjam.com/go/live/82/l3w81i68s2s4sk',

    awswebinar:'https://event.webinarjam.com/go/live/106/r1qprunraqs6sl',

    ytwebinar:'https://youtu.be/lhFhUHpC4vw',

    ddchandana:'https://www.youtube.com/watch?v=y9yhmSQ6qlw',

    joshtalks:'https://www.youtube.com/watch?v=bzl_X7dRo8k',

    aptitude:'https://microdegree.thinkific.com/courses/aptitude',

    interview_prep:'https://microdegree.thinkific.com/courses/microdegree-interview-prepration',

    python_course:'https://microdegree.thinkific.com/courses/python',

    resume:'https://www.microdegree.work/resume',

    video_mp4:'https://vimeo.com/935300502?share=copy',

    webinar:'https://www.microdegree.work/webinar',

    topper_of_the_month:'https://trainings.microdegree.work/topper-of-the-month',

    mangalore:'https://g.page/r/Ce1u9oaiRnq7EAI/review',

    bangalore:'https://g.page/r/CRexjhkTagrEEAI/review',

    igbebook:'https://tally.so/r/mOzXBp?utm_source=ig&utm_medium=bio&utm_content=message&utm_campaign=registration',

    igstrebook:'https://tally.so/r/mOzXBp?utm_source=ig&utm_medium=stry&utm_content=message&utm_campaign=registration',

    igdmebook:'https://tally.so/r/mOzXBp?utm_source=ig&utm_medium=dm&utm_content=message&utm_campaign=registration',

    tgbebook:'https://tally.so/r/mOzXBp?utm_source=tg&utm_medium=bio&utm_content=message&utm_campaign=registration',

    curriculum:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FAWS%20%26%20DevOps%20Notes-Updated%20(5).pdf?alt=media&token=4db2b41c-347d-4882-afda-89d904577239',

    aws_devops_curriculum:' https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/eBook%2FAWS%20%26%20DevOps%20Notes-Updated.pdf?alt=media&token=9e0e00bb-d272-4510-a2bb-f9371fd56ed8',
    
    curriculum_:'https://tally.so/r/wbOQP7?utm_source=sm&utm_medium=aws_devops&utm_content=curriculum&utm_campaign=registration',

    awsdevops_curriculum:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/ebook%20whatsapp%20catelog%2FAWS%20%26%20DevOps%20Notes-Premium-1%20(1).pdf?alt=media&token=4f2bfd07-fbb6-4bcd-b98b-388deb164af3',

    pythonfullstack_curriculum:'https://firebasestorage.googleapis.com/v0/b/md-android-f745b.appspot.com/o/ebook%20whatsapp%20catelog%2FPython%20Fullstack%20Curriculum%20(1).pdf?alt=media&token=e65f4ec7-9364-4877-8d08-aaed5e95c664',

    e_book:'https://tally.so/r/3X0Jk4?utm_source=pdf&utm_medium=page&utm_content=ebook&utm_campaign=registration',

    e_book_:'https://tally.so/r/3X0Jk4?utm_source=pdf&utm_medium=page&utm_content=ebook&utm_campaign=registration',

    devops_live_august:'https://tally.so/r/mBZWpe',
    aws_live_august:'https://tally.so/r/mBZWpe',
    python_live_september:'https://tally.so/r/mBZWpe',


    wa_chat:'https://tally.so/r/3X0Jk4?utm_source=wa&utm_medium=chatbot&utm_content=wati&utm_campaign=enquiry',
    wati_webinar_reg:'https://tally.so/r/w4Bz9A?utm_source=wa&utm_medium=wati&utm_content=webinar&utm_campaign=registration',
    webinar_reg_wati:'https://tally.so/r/m6dVjA?utm_source=wa&utm_medium=wati&utm_content=webinar&utm_campaign=registration',


    ytpost:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=post&utm_content=testimonial&utm_campaign=enquiry',
    ytquiz:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=post&utm_content=quiz&utm_campaign=enquiry',
    ytreview:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=post&utm_content=review&utm_campaign=enquiry',
    yttesti:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=testi&utm_content=testimonial&utm_campaign=enquiry',
    ytpc:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=pc&utm_content=podcast&utm_campaign=enquiry',
    ytdm:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dm&utm_content=dm&utm_campaign=enquiry',
    ytregspage:'https://page.microdegree.work/registration?utm_source=yt&utm_medium=dscr&utm_content=demo&utm_campaign=registration',
    ytregwebinar:'https://tally.so/r/w4Bz9A?utm_source=yt&utm_medium=dscr-post&utm_content=webinar&utm_campaign=registration',
    ytregebook:'https://tally.so/r/mOzXBp?utm_source=yt&utm_medium=dscr&utm_content=message&utm_campaign=registration',
    ytpost_testi:'https://tally.so/r/3y6Qeg?utm_source=yt&utm_medium=post&utm_content=testimonials-post&utm_campaign=enquiry',

    ytcm_python:'https://courses.microdegree.work/courses/python',
    ytcm_python_fullstack:'https://trainings.microdegree.work/python-fullstack',

    ytdscr_courses:'https://bit.ly/3Ku9aAm',
    ytdscr_channel:'https://bit.ly/3ajK4Cz',
    
    ytcm_awslive1:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=hwo5ymx_Xos&utm_campaign=liveclass',
    ytcm_awslive2:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=iCqzdkqKUg4&utm_campaign=liveclass',
    ytcm_awslive3:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=vftSvlIzQGk&utm_campaign=liveclass',
    ytcm_awslive4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=8H6wUuIh4Kc&utm_campaign=liveclass',
    ytcm_awslive5:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=6JhZBLwWWo0&utm_campaign=liveclass',
    ytcm_awslive6:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=rf4Y34P7Hmg&utm_campaign=liveclass',
    ytcm_awslive7:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qP82muzcpI8&utm_campaign=liveclass',
    ytcm_awslive8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=NJ3jvDC_2nw&utm_campaign=liveclass',
    
    ytdscr_awslive1:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=hwo5ymx_Xos&utm_campaign=liveclass',
    ytdscr_awslive2:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=iCqzdkqKUg4&utm_campaign=liveclass',
    ytdscr_awslive3:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=vftSvlIzQGk&utm_campaign=liveclass',
    ytdscr_awslive4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=8H6wUuIh4Kc&utm_campaign=liveclass',
    ytdscr_awslive5:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=6JhZBLwWWo0&utm_campaign=liveclass',
    ytdscr_awslive6:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=rf4Y34P7Hmg&utm_campaign=liveclass',
    ytdscr_awslive7:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=qP82muzcpI8&utm_campaign=liveclass',
    ytdscr_awslive8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=dscr&utm_content=NJ3jvDC_2nw&utm_campaign=liveclass',

    igstory:'https://tally.so/r/3X0Jk4?utm_source=ig&utm_medium=str&utm_content=story&utm_campaign=enquiry',
    igdm_enq:'https://tally.so/r/3X0Jk4?utm_source=ig&utm_medium=dm&utm_content=message&utm_campaign=enquiry',
    igbio_enq:'https://tally.so/r/3X0Jk4?utm_source=ig&utm_medium=bio&utm_content=profile-dscr&utm_campaign=enquiry',
    igbio_regspage:'https://page.microdegree.work/registration?utm_source=ig&utm_medium=bio&utm_content=demo&utm_campaign=registration',
    igbio_webinar:'https://tally.so/r/w4Bz9A?utm_source=ig&utm_medium=bio-story&utm_content=webinar&utm_campaign=registration',
    igcom:'https://tally.so/r/3X0Jk4?utm_source=ig&utm_medium=cm&utm_content=comments&utm_campaign=enquiry',
    igstr_testi:'https://tally.so/r/3y6Qeg?utm_source=ig&utm_medium=str&utm_content=testimonials-post&utm_campaign=enquiry',

    
    tgpost_webinar:'https://tally.so/r/w4Bz9A?utm_source=tg&utm_medium=post&utm_content=webinar&utm_campaign=registration',
    tgdm:'https://tally.so/r/3X0Jk4?utm_source=tg&utm_medium=bio&utm_content=message&utm_campaign=enquiry',

    fbdscr_webinar:'https://tally.so/r/w4Bz9A?utm_source=fb&utm_medium=dscr-post&utm_content=webinar&utm_campaign=registrations',
    fbbio_enq:'https://tally.so/r/3X0Jk4?utm_source=fb&utm_medium=bio&utm_content=profile-dscr&utm_campaign=enquiry',
    fbbio_regebook:'https://tally.so/r/mOzXBp?utm_source=fb&utm_medium=bio&utm_content=message&utm_campaign=registration',
    fbcom_enq:'https://tally.so/r/3X0Jk4?utm_source=fb&utm_medium=cm&utm_content=comments&utm_campaign=enquiry',
    fbdm_enq:'https://tally.so/r/3X0Jk4?utm_source=fb&utm_medium=dm&utm_content=message&utm_campaign=enquiry',
    fbdm_regebook:'https://tally.so/r/mOzXBp?utm_source=fb&utm_medium=dm&utm_content=message&utm_campaign=registration',
    fbstory_enq:'https://tally.so/r/3X0Jk4?utm_source=fb&utm_medium=str&utm_content=story&utm_campaign=enquiry',
    fbbio_regspage:'https://page.microdegree.work/registration?utm_source=fb&utm_medium=bio&utm_content=demo&utm_campaign=registration',
    fbad_regspage:'https://page.microdegree.work/registration?utm_source=fb&utm_medium=ad&utm_content=demo&utm_campaign=registration',
    fbpost_testi:'https://tally.so/r/3y6Qeg?utm_source=fb&utm_medium=post&utm_content=testimonials-post&utm_campaign=enquiry',
    fbad_chatbot: 'https://tally.so/r/3xQOoo?utm_source=fb-ad&utm_medium=wa-chat&utm_content=message&utm_campaign=registration',
    fbad_igdm: 'https://tally.so/r/3xQOoo?utm_source=fb-ad&utm_medium=ig-dm&utm_content=message&utm_campaign=registration',


    
    lidm_enq:'https://tally.so/r/3X0Jk4?utm_source=li&utm_medium=dm&utm_content=message&utm_campaign=enquiry',
    libio_enq:'https://tally.so/r/3X0Jk4?utm_source=li&utm_medium=bio&utm_content=profile-dscr&utm_campaign=enquiry',
    lipost_webinar:'https://tally.so/r/w4Bz9A?utm_source=li&utm_medium=post&utm_content=webinar&utm_campaign=registration',
    libio_regebook:'https://tally.so/r/mOzXBp?utm_source=li&utm_medium=bio&utm_content=message&utm_campaign=registration',
    lidscr_liveproj:'https://tally.so/r/3X0Jk4?utm_source=li&utm_medium=desc&utm_content=liveproject&utm_campaign=enquiry',
    lipost_testi:'https://tally.so/r/3y6Qeg?utm_source=li&utm_medium=post&utm_content=testimonials-post&utm_campaign=enquiry',
    li_blogs:'https://tally.so/r/mOAAEY?utm_source=li&utm_medium=post&utm_content=blogs&utm_campaign=registration',
    li_live_vd:'https://tally.so/r/mOAAEY?utm_source=li&utm_medium=post&utm_content=live_vd&utm_campaign=registration',
    li_css_blogs:'https://tally.so/r/3ylqax?utm_source=li&utm_medium=post&utm_content=Css_blogs&utm_campaign=registration',
    li_mysql_blogs:'https://tally.so/r/3ylqax?utm_source=li&utm_medium=post&utm_content=mysql_blogs&utm_campaign=registration',
    li_java_blogs:'https://tally.so/r/3ylqax?utm_source=li&utm_medium=post&utm_content=java_free_course&utm_campaign=registration',
    li_php_blogs:'https://www.youtube.com/watch?v=jaodrLJle-M',
    li_html_css_blogs:'https://www.youtube.com/watch?v=y6fzqBsi1-Y',
    li_js_blogs:'https://www.youtube.com/watch?v=ULzUzc0gMfI',
    li_react_blogs:'https://www.youtube.com/watch?v=-tzG0bjZV7A',
    li_my_sql_blogs:'https://www.youtube.com/watch?v=exqFMNJKDOE',




    free_courses_cb:'https://trainings.microdegree.work/learn/categories/free-courses?show=all&type=100&categoryCustomName=free-courses',
    courses_cb:'https://trainings.microdegree.work/learn/view-all?show=courses&type=1',
    aws_devops_cb:'https://courses.microdegree.work/courses/aws-developer-certification-live',
    python_fullstack_cb:'https://trainings.microdegree.work/python-fullstack',
    mdpro_bundle_cb:'https://trainings.microdegree.work/learn/MicroDegree-Pro-Bundle',

    li_blog:'https://tally.so/r/3jB9V4?utm_source=li&utm_medium=blog&utm_content=demo&utm_campaign=registrations',
    li_python_course:'https://tally.so/r/3ylqax?utm_source=li&utm_medium=blog&utm_content=python&utm_campaign=registrations',
    li_git_course:'https://tally.so/r/3ylqax?utm_source=li&utm_medium=blog&utm_content=git&utm_campaign=registrations',



    web_webinar:'https://tally.so/r/w4Bz9A?utm_source=webi&utm_medium=page&utm_content=webinar&utm_campaign=registration',
    web_webinar_:'https://tally.so/r/m6dVjA?utm_source=webi&utm_medium=page&utm_content=webinar&utm_campaign=registration',
    web_cbot_webinar:'https://www.microdegree.work/webinar?utm_source=chatbot&utm_medium=webi_page&utm_content=webinar&utm_campaign=registration',
    web_regspage:'https://tally.so/r/wve8pl?utm_source=web&utm_medium=page&utm_content=demo&utm_campaign=registration',
    web_regsdpage:'https://tally.so/r/mO4Zpg?utm_source=web&utm_medium=page&utm_content=short-demo&utm_campaign=registration',
    web_regebook:'https://tally.so/r/mOzXBp?utm_source=web&utm_medium=learnyst_page&utm_content=ebook&utm_campaign=registration',
    web_regawsit:'https://tally.so/embed/3NoYDO?utm_source=web&utm_medium=page&utm_content=free-course&utm_campaign=registration',
    web_reg_nonit:'https://tally.so/embed/3qV98k?utm_source=web&utm_medium=page&utm_content=free-demo&utm_campaign=registration',
    web_regawsit_in:'https://tally.so/embed/mBkXY4?utm_source=web&utm_medium=page&utm_content=freecourse&utm_campaign=registration',
    web_reg_nonit_in:'https://tally.so/embed/wQ5X7k?utm_source=web&utm_medium=page&utm_content=freedemo&utm_campaign=registration',
    web_regtoppage:'https://tally.so/r/n9DK8K?utm_source=web&utm_medium=page&utm_content=placement&utm_campaign=registration',
    web_awsdevops_live_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=aws&devops_live&utm_campaign=enquiry',
    web_python_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=python&utm_campaign=enquiry',
    web_mysql_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=mysql&utm_campaign=enquiry',
    web_javascript_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=javascript&utm_campaign=enquiry',
    web_reactjs_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=reactjs&utm_campaign=enquiry',
    web_development_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=web_development&utm_campaign=enquiry',
    web_data_analytics_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=data_analytics&utm_campaign=enquiry',
    web_frontend_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=fontend_development&utm_campaign=enquiry',
    web_software_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=software_developer&utm_campaign=enquiry',
    web_automation_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=automation_testing&utm_campaign=enquiry',
    web_aptitude_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=aptitude&utm_campaign=enquiry',
    web_CProgramming_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=c_programming&utm_campaign=enquiry',
    web_HtmlCss_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=html&css&utm_campaign=enquiry',
    web_php_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=php&utm_campaign=enquiry',
    web_awsdevops_rec_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=aws&devops_recorded&utm_campaign=enquiry',
    web_aws_rec_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=aws_recorded&utm_campaign=enquiry',
    web_devops_rec_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=devops_recorded&utm_campaign=enquiry',
    web_AWS_rec_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=AWS_recorded&utm_campaign=enquiry',
    web_DevOps_rec_enq:'https://tally.so/r/nGzqgp?utm_source=web&utm_medium=page&utm_content=Devops_recorded&utm_campaign=enquiry',
    web_placement_reg:'https://www.microdegree.work/Offcampus_PlacementDrive_Form?utm_source=web&utm_medium=page&utm_content=placement_drive&utm_campaign=registration',
    web_aws_free_course:'https://www.microdegree.work/aws_course_details_page',
    web_python_free_course:'https://www.microdegree.work/python_course_details_page',
    web_mysql_free_course:'https://www.microdegree.work/mysql_course_details_page',
    web_cloud_placementdrive:'https://www.microdegree.work/Cloud_PlacementDrive_Form',
    web_placement_testimonial:'https://www.microdegree.work/Testimonials',
    web_testimonial_fresher:'https://www.microdegree.work/testimonialFreshers',
    web_testimonial_experienced:'https://www.microdegree.work/testimonialExperienced',
    web_video_testimonial:'https://www.microdegree.work/TestimonialsPage',
    web_referral:'https://tally.so/r/mV0adN?utm_source=web&utm_medium=page&utm_content=referral&utm_campaign=registration',
    web_page_referral:'https://tally.so/r/mV0adN?utm_source=web&utm_medium=course_page&utm_content=referral&utm_campaign=registration',
    web_Learnyst_referral:'https://tally.so/r/mV0adN?utm_source=web&utm_medium=learnyst_page&utm_content=referral&utm_campaign=registration',
    web_mdpage:'https://microdegree.work?utm_source=web&utm_medium=page&utm_content=website&utm_campaign=registration',
    md_linkedIn:'https://www.linkedin.com/company/microdegree/?utm_source=web&utm_medium=page&utm_content=LinkedIn&utm_campaign=registration',
    md_facebook:'https://www.facebook.com/microdegree?utm_source=web&utm_medium=page&utm_content=Facebook&utm_campaign=registration',
    md_instagram:'https://www.instagram.com/microdegree.work?utm_source=web&utm_medium=page&utm_content=Instagram&utm_campaign=registration',
    md_twitter:'https://twitter.com/micro_degree?utm_source=web&utm_medium=page&utm_content=Twitter&utm_campaign=registration',
    md_telegram:'https://telegram.me/microdegreekannada?utm_source=web&utm_medium=page&utm_content=Telegram&utm_campaign=registration',
    web_pages_testimonialpage:'https://www.microdegree.work/Testimonialspage',
    web_pages_testimonials:'https://www.microdegree.work/Testimonials',
    webinars_:'https://www.microdegree.work/webinar',
    scholorship_web:'https://tally.so/r/mOzMQY?utm_source=web&utm_medium=page&utm_content=scholorship&utm_campaign=registration',



    webjam_demo_sheetfill_link:'https://forms.gle/bBWwcqDyjQTpXDb59?utm_source=webinarjam&utm_medium=post&utm_content=demo&utm_campaign=sheetfill',
    yt_demo_sheetfill_link:'https://forms.gle/bBWwcqDyjQTpXDb59?utm_source=yt&utm_medium=post&utm_content=demo&utm_campaign=sheetfill',
   

    wa_regsdpage:'https://page.microdegree.work/shortdemo?utm_source=wa&utm_medium=automation&utm_content=shortdemo&utm_campaign=short-msg',
    wa_st_sdreg:'https://page.microdegree.work/shortdemo?utm_source=wa&utm_medium=st&utm_content=stshortdemo&utm_campaign=short-demo-msg',
    wa_eve_demo:'https://us06web.zoom.us/j/87833283242?pwd=Q25FbTVoMFN6aFJPNTJ2YTZZaDRHZz09?utm_source=wati&utm_medium=chatbot&utm_content=demo&utm_campaign=registration',
    wa_mor_demo:'https://us06web.zoom.us/j/86197263158?pwd=bXdEZi9BZ3lDdWxFclo0cktReHVCZz09?utm_source=wati&utm_medium=chatbot&utm_content=demo&utm_campaign=registration',
    sms_mor_demo:'https://us06web.zoom.us/meeting/register/tZIsfu6qqDgrH9z3ftHwQhntnQQIetUOyypS?utm_source=sms&utm_medium=text&utm_content=demo&utm_campaign=registration',
    sms_eve_demo:'https://us06web.zoom.us/meeting/register/tZMldOqqpjgoHtZHQP1JxsZYsAhhDNnG6Vhj?utm_source=sms&utm_medium=text&utm_content=demo&utm_campaign=registration',

    email_webinar:'https://tally.so/r/w4Bz9A?utm_source=flo-dk&utm_medium=email&utm_content=webinar&utm_campaign=registration',    
    webinar_reg_email:'https://tally.so/r/m6dVjA?utm_source=flodesk&utm_medium=email&utm_content=webinar&utm_campaign=registration',

    zm_sheet:'https://tally.so/r/npDapy?utm_source=wbjm-zm&utm_medium=demo&utm_content=message&utm_campaign=sheetfill',
    yt_sheet:'https://forms.gle/99GdhBcYbM7ip4gi7?utm_source=wbjm-zm&utm_medium=post&utm_content=description&utm_campaign=sheetfill',

    onboarding_videos_wati:'https://youtu.be/HdrC7qTQQ8I?si=EOL4_lJ97M9tNc14',
    onboarding_videos_email:'https://youtu.be/HdrC7qTQQ8I?si=EOL4_lJ97M9tNc14',

    onboarding_podcast_wati:'https://youtu.be/q0ev6AAO0LU?si=kfFlEP8u3zgwGk4N',
    onboarding_podcast_email:'https://youtu.be/q0ev6AAO0LU?si=kfFlEP8u3zgwGk4N',

    generic_channel_email:'http://www.youtube.com/@MicroDegree',

    zoom_joining_tg:'http://www.youtube.com/@MicroDegree',

    aws_job_interviews_tg:'https://youtu.be/67tzmZvIVn8?si=FkqbOYPlbpv4Ce7Z',
    aws_job_interviews_email:'https://youtu.be/67tzmZvIVn8?si=FkqbOYPlbpv4Ce7Z',

    aws_chatgpt_tg:'https://youtu.be/7dDceQ__cl8?si=dBexUZ6vR949-y-b',
    aws_chatgpt_email:'https://youtu.be/7dDceQ__cl8?si=dBexUZ6vR949-y-b',

    aws_devops_engineer_tg:'https://youtu.be/Qr7gouw2eFA?si=AVLO9oM8cWlogUeG',
    aws_devops_engineer_email:'https://youtu.be/Qr7gouw2eFA?si=AVLO9oM8cWlogUeG',

    aws_certificate_wati:'http://www.youtube.com/@MicroDegree',
    aws_certificate_email:'http://www.youtube.com/@MicroDegree',

    placement_prep_tc:'http://www.youtube.com/@MicroDegree',
    
    devops_cicd_tg:'https://youtu.be/ySgzIZVrPSE?si=w5wkFyoXVdvf5SRf',
    devops_cicd_email:'https://youtu.be/ySgzIZVrPSE?si=w5wkFyoXVdvf5SRf',

    devops_fresher_tg:'https://youtu.be/RTXuZiSKty0?si=dxa-DhkIa5rDV5xf',
    devops_fresher_email:'https://youtu.be/RTXuZiSKty0?si=dxa-DhkIa5rDV5xf',

    devops_certificate_wati:'http://www.youtube.com/@MicroDegree',
    devops_certificate_email:'http://www.youtube.com/@MicroDegree',





    yt_VZ5lAiJ8UmM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=VZ5lAiJ8UmM&utm_campaign=enquiry',
    yt_MjOo6jNRJfc:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=MjOo6jNRJfc&utm_campaign=enquiry',
    yt_Qr7gouw2eFA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Qr7gouw2eFA&utm_campaign=enquiry',
    yt_Ll16GbHz1iw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Ll16GbHz1iw&utm_campaign=enquiry',
    yt_c9ezexptV2M:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=c9ezexptV2M&utm_campaign=enquiry',
    yt_67tzmZvIVn8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=67tzmZvIVn8&utm_campaign=enquiry',
    yt_HdrC7qTQQ8I:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=HdrC7qTQQ8I&utm_campaign=enquiry',
    yt_RTXuZiSKty0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=RTXuZiSKty0&utm_campaign=enquiry',
    yt_G5C3wKmLehI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=G5C3wKmLehI&utm_campaign=enquiry',
    yt_ypAxo6HHLd0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ypAxo6HHLd0&utm_campaign=enquiry',
    yt_NYfizBNply4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=NYfizBNply4&utm_campaign=enquiry',
    yt_ANBh3h9J58g:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ANBh3h9J58g&utm_campaign=enquiry',
    yt_Lj2bLGhR6X0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Lj2bLGhR6X0&utm_campaign=enquiry',
    yt_EPWiAonAEL4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=EPWiAonAEL4&utm_campaign=enquiry',
    yt_Rwf8uuJuG_M:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Rwf8uuJuG-M&utm_campaign=enquiry',
    yt_CfOsFrYA4Wg:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=CfOsFrYA4Wg&utm_campaign=enquiry',
    yt__o98pk_kfA8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=_o98pk_kfA8&utm_campaign=enquiry',
    yt_KRap60TCFMs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=KRap60TCFMs&utm_campaign=enquiry',
    yt_nB8sXL_GOJA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=nB8sXL_GOJA&utm_campaign=enquiry',
    yt_fEPxDVoqQ3k:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=fEPxDVoqQ3k&utm_campaign=enquiry',
    yt_qc3PqIaI7KQ:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qc3PqIaI7KQ&utm_campaign=enquiry',
    yt_l0FkE9P4MiM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=l0FkE9P4MiM&utm_campaign=enquiry',
    yt_IltWz6BzlEw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=IltWz6BzlEw&utm_campaign=enquiry',
    yt_PPwARjLTA3k:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=PPwARjLTA3k&utm_campaign=enquiry',
    yt_lCcLo0ryapI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=lCcLo0ryapI&utm_campaign=enquiry',
    yt_zjKL3vpQ6jA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=zjKL3vpQ6jA&utm_campaign=enquiry',
    yt_FVriF8IxE9g:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=FVriF8IxE9g&utm_campaign=enquiry',
    yt_zBvy9OgRPng:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=zBvy9OgRPng&utm_campaign=enquiry',
    yt_t8ySphnzxoM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=t8ySphnzxoM&utm_campaign=enquiry',
    yt_xxhSK8XjglA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=xxhSK8XjglA&utm_campaign=enquiry',
    yt_ehrD5gV06X4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ehrD5gV06X4&utm_campaign=enquiry',
    yt_DTFrpbkmoWw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=DTFrpbkmoWw&utm_campaign=enquiry',
    yt_L7SQRmHx74:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=L7SQ-RmHx74&utm_campaign=enquiry',
    yt_rbKVhx9baAQ:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=rbKVhx9baAQ&utm_campaign=enquiry',
    yt_Km7BetGgAt4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Km7BetGgAt4&utm_campaign=enquiry',
    yt_2SvAyYHihjg:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=2SvAyYHihjg&utm_campaign=enquiry',
    yt_vAxdZDRrxaU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=vAxdZDRrxaU&utm_campaign=enquiry',
    yt_1ogOokEdUFU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=1ogOokEdUFU&utm_campaign=enquiry',
    yt_C4o6eIo8DSM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=C4o6eIo8DSM&utm_campaign=enquiry',
    yt_7vXsdOUhBiU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=7vXsdOUhBiU&utm_campaign=enquiry',
    yt_5ZQwHI5r_7M:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=5ZQwHI5r_7M&utm_campaign=enquiry',
    yt_IMhVkmIL_g:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=-IMhVkmIL_g&utm_campaign=enquiry',
    yt_jF8oz3Ieb4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=jF-8oz3Ieb4&utm_campaign=enquiry',
    yt_ZdRDK_o99NM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ZdRDK_o99NM&utm_campaign=enquiry',
    yt_f9YI1EmIXxE:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=f9YI1EmIXxE&utm_campaign=enquiry',
    yt_XduzW_Uw5wU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=XduzW_Uw5wU&utm_campaign=enquiry',
    yt_w8nQZvv2c1Q:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=w8nQZvv2c1Q&utm_campaign=enquiry',
    yt_FkyxAbRxam4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=FkyxAbRxam4&utm_campaign=enquiry',
    yt_gtPSrGhdtM8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=gtPSrGhdtM8&utm_campaign=enquiry',
    yt_XDuDQA1lC1s:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=XDuDQA1lC1s&utm_campaign=enquiry',
    yt_gVaEzeOB63Y:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=gVaEzeOB63Y&utm_campaign=enquiry',
    yt_t3g494yxPQk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=t3g494yxPQk&utm_campaign=enquiry',
    yt_Y6St354NVt4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Y6St354NVt4&utm_campaign=enquiry',
    yt_qFX6reysqz4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qFX6reysqz4&utm_campaign=enquiry',
    yt_PHMhPQvpo0s:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=PHMhPQvpo0s&utm_campaign=enquiry',
    yt_dumoqu8j9U0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=dumoqu8j9U0&utm_campaign=enquiry',
    yt_1_AUJS5_KYk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=1_AUJS5-KYk&utm_campaign=enquiry',
    yt_dDzrmC1RegA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=dDzrmC1RegA&utm_campaign=enquiry',
    yt_y8FhpXzF8kI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=y8FhpXzF8kI&utm_campaign=enquiry',
    yt_K6SMdv0JXj8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=K6SMdv0JXj8&utm_campaign=enquiry',
    yt_jXmwWy4KyqA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=jXmwWy4KyqA&utm_campaign=enquiry',
    yt_jTTQn8qL6rk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=jTTQn8qL6rk&utm_campaign=enquiry',
    yt_0mU_toVQ2QM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=0mU_toVQ2QM&utm_campaign=enquiry',
    yt_SzS8_O5fRS0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=SzS8_O5fRS0&utm_campaign=enquiry',
    yt_B_0ewIGUihs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=B_0ewIGUihs&utm_campaign=enquiry',
    yt_LaQRNri5zSo:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=LaQRNri5zSo&utm_campaign=enquiry',
    yt_mEnON1KdPC0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=mEnON1KdPC0&utm_campaign=enquiry',
    yt_XdLX37YatPs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=XdLX37YatPs&utm_campaign=enquiry',
    yt_s_22a5YYxR0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=s-22a5YYxR0&utm_campaign=enquiry',
    yt_z44S6Y3oX_Q:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=z44S6Y3oX_Q&utm_campaign=enquiry',
    yt_idN7joUx0Yk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=idN7joUx0Yk&utm_campaign=enquiry',
    yt_tJ3omuF8za8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=tJ3omuF8za8&utm_campaign=enquiry',
    yt_ob6Z6Zvxzyc:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ob6Z6Zvxzyc&utm_campaign=enquiry',
    yt_JYUVEGYrbEM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=JYUVEGYrbEM&utm_campaign=enquiry',
    yt_v1deNVTawTI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=v1deNVTawTI&utm_campaign=enquiry',
    yt_rnuOPMObSB4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=rnuOPMObSB4&utm_campaign=enquiry',
    yt_ayF9k02iiYs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ayF9k02iiYs&utm_campaign=enquiry',
    yt_sK_psHYOe1A:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=sK-psHYOe1A&utm_campaign=enquiry',
    yt_snR2qTea6Fo:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=snR2qTea6Fo&utm_campaign=enquiry',
    yt_imutI_hmkkc:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=imutI-hmkkc&utm_campaign=enquiry',
    yt_ltirsj1pTj4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ltirsj1pTj4&utm_campaign=enquiry',
    yt_RsScrOqfue0:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=RsScrOqfue0&utm_campaign=enquiry',
    yt_b_BbVEF0Shw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=b_BbVEF0Shw&utm_campaign=enquiry',
    yt_8svDvI2yblE:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=8svDvI2yblE&utm_campaign=enquiry',
    yt_zhbGbE6N77I:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=zhbGbE6N77I&utm_campaign=enquiry',
    yt_cZsPjlNyf2c:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=cZsPjlNyf2c&utm_campaign=enquiry',
    yt_zzVns1ADNzo:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=zzVns1ADNzo&utm_campaign=enquiry',
    yt_2IpCRR10BBM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=2IpCRR10BBM&utm_campaign=enquiry',
    yt_wcHExYstWVA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=wcHExYstWVA&utm_campaign=enquiry',
    yt_qqzdEdVN_fE:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qqzdEdVN-fE&utm_campaign=enquiry',
    yt_W0epzzE4sDY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=W0epzzE4sDY&utm_campaign=enquiry',
    yt_sSHxT3fuAGU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=sSHxT3fuAGU&utm_campaign=enquiry',
    yt_u0HNJ_1_gdI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=u0HNJ_1_gdI&utm_campaign=enquiry',
    yt_qIUHiviU0Pk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qIUHiviU0Pk&utm_campaign=enquiry',
    yt_EpLBupS03Ws:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=EpLBupS03Ws&utm_campaign=enquiry',
    yt_Xa_Rxc3iYhk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Xa_Rxc3iYhk&utm_campaign=enquiry',
    yt_GYmqxFL5koU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=GYmqxFL5koU&utm_campaign=enquiry',
    yt_85pDTIvFBCA:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=85pDTIvFBCA&utm_campaign=enquiry',
    yt_lnDiTJT1juU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=lnDiTJT1juU&utm_campaign=enquiry',
    yt_xQzvZ6f8TVs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=xQzvZ6f8TVs&utm_campaign=enquiry',
    yt_PvE7LWs4Vfw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=PvE7LWs4Vfw&utm_campaign=enquiry',
    yt_8xywohrHv8s:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=8xywohrHv8s&utm_campaign=enquiry',
    yt_m9EWfNW8U5Q:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=m9EWfNW8U5Q&utm_campaign=enquiry',
    yt_hAw5WOwMXUY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=hAw5WOwMXUY&utm_campaign=enquiry',
    yt_gERELZv09gQ:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=gERELZv09gQ&utm_campaign=enquiry',
    yt_p2tU4W0MVLQ:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=p2tU4W0MVLQ&utm_campaign=enquiry',
    yt_qjG1mjGBfSw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=qjG1mjGBfSw&utm_campaign=enquiry',
    yt_9AyMtJTuJkI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=9AyMtJTuJkI&utm_campaign=enquiry',
    yt_tx0gZiSOoFc:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=tx0gZiSOoFc&utm_campaign=enquiry',
    yt_Go7l8ZyUsN4:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Go7l8ZyUsN4&utm_campaign=enquiry',
    yt_n_v5GGItXZg:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=n_v5GGItXZg&utm_campaign=enquiry',
    yt_0GUT9L3MqBQ:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=0GUT9L3MqBQ&utm_campaign=enquiry',
    yt_h5hWsmirwPY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=h5hWsmirwPY&utm_campaign=enquiry',
    yt_mymTVCGb8SY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=mymTVCGb8SY&utm_campaign=enquiry',
    yt_IVVTUo_Mk9k:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=IVVTUo-Mk9k&utm_campaign=enquiry',
    yt_mV8yxNAgOBk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=mV8yxNAgOBk&utm_campaign=enquiry',
    yt_sjtAOLQt2mw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=sjtAOLQt2mw&utm_campaign=enquiry',
    yt_gn7_NYVGqAw:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=gn7-NYVGqAw&utm_campaign=enquiry',
    yt_0UBbaPUpW40:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=0UBbaPUpW40&utm_campaign=enquiry',
    yt_lIiN4zkOESg:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=lIiN4zkOESg&utm_campaign=enquiry',
    yt_oTYGgZROBlY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=oTYGgZROBlY&utm_campaign=enquiry',
    yt_fcapFb28D1U:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=fcapFb28D1U&utm_campaign=enquiry',
    yt_ewS0Xw8gBd8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ewS0Xw8gBd8&utm_campaign=enquiry',
    yt_NmtoYcfRQMI:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=NmtoYcfRQMI&utm_campaign=enquiry',
    yt_01qSumyUrpU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=01qSumyUrpU&utm_campaign=enquiry',
    yt_ykwt6HMtK4o:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ykwt6HMtK4o&utm_campaign=enquiry',
    yt_hzKH7miNe7g:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=hzKH7miNe7g&utm_campaign=enquiry',
    yt__uOuoM5LlRk:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=_uOuoM5LlRk&utm_campaign=enquiry',
    yt_lKIXG7Ot76U:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=lKIXG7Ot76U&utm_campaign=enquiry',
    yt_zu4StFoP914:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=zu4StFoP914&utm_campaign=enquiry',
    yt_abIjfcx0FKE:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=abIjfcx0FKE&utm_campaign=enquiry',
    yt_t137ec05cRM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=t137ec05cRM&utm_campaign=enquiry',
    yt_bWadycnxzko:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=bWadycnxzko&utm_campaign=enquiry',
    yt_6ZGtOeX8:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=6ZGtOe---X8&utm_campaign=enquiry',
    yt_m9zTsJJE7Yg:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=m9zTsJJE7Yg&utm_campaign=enquiry',
    yt_q0ev6AAO0LU:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=q0ev6AAO0LU&utm_campaign=enquiry',
    yt_ySgzIZVrPSE:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=ySgzIZVrPSE&utm_campaign=enquiry',
    yt_s2hhYIY6s9c:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=s2hhYIY6s9c&utm_campaign=enquiry',
    yt_jw3zylNUu5g:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=jw3zylNUu5g&utm_campaign=enquiry',
    yt_Iy_TldR5TCY:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=Iy_TldR5TCY&utm_campaign=enquiry',
    yt_3_Ywr253eHs:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=3_Ywr253eHs&utm_campaign=enquiry',
    yt_xyRx9k4Ou34:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=xyRx9k4Ou34&utm_campaign=enquiry',
    yt__iIWDQydUEM:'https://tally.so/r/3X0Jk4?utm_source=yt&utm_medium=cm&utm_content=_iIWDQydUEM&utm_campaign=enquiry',



  };

  useEffect(() => {
    // Get the current URI from the URL path
    const currentUri = window.location.pathname.replace(/^\//, '').replace(/^\/basepath\//, ''); // Remove leading slash

    // Check if the URI exists in the mapping
    if (customUriMappings.hasOwnProperty(currentUri)) {
      // Redirect to the original URL
      window.location.replace(customUriMappings[currentUri]);

      console.log(`Short URL clicked: https://mdegree.in/${currentUri}`);
    } else {
      // Handle case when URI is not found
      console.error('Custom URI not found or invalid.');
    }
  }, []);


  return (
    <div className="sm:mx-5 block sm:hidden">
    <center><h1 style={{marginTop:"200px",fontSize:"18px",}}>Redirecting ....</h1>
    <h1 style={{paddingBottom:"50px",color:"blue", fontSize:"18px"}}><b>"All power is within you; you can do anything and everything"</b></h1>
    </center>
  </div>
  );
};


export default ShortenerPage;
